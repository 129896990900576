<template>
  <div>
    <div class="flex items-center rounded-md mb-5 mt-1.5 md:px-0">
      <UIExit label="Exit Roles & Access" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import UIExit from '@/src/components/UiElements/UIExit.vue'
export default {
  components: {
    UIExit,
  },
  data() {
    return {
      rightBar: 'rightBar',
    }
  },
  created() {
    this.setLeftBar()
  },
  methods: {
    setLeftBar() {
      this.setLeftbarContent({
        back: '',
        customeHeader: 'left-bar-class-list',
        routes: [
          {
            id: '0001',
            icon: 'users',
            name: 'ROLES_AND_ACCESS',
            route: { name: 'roles-and-acccess-management' },
            role: ['super_admin', 'campus_admin'],
          },
        ],
      })
    },
    ...mapActions('layout', ['setLeftbarContent']),
  },
}
</script>

<style lang="scss" scoped>
.exit-access-mode {
  display: block;
}
</style>
